<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">Broadcast Channel</div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <!-- <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/> -->
          <!-- <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <!-- <el-select size="small" v-model="user.searchType" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <el-button type="primary" v-b-modal.add size="small"
            ><font-awesome-icon icon="search" /> Create Broadcast</el-button
          >
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Avatar</th>
              <th width="20%">Name</th>
              <th width="10%">Type</th>
              <th>Is Online</th>
              <th>Created at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in listBroadcast" :key="item._id">
              <td>
                <template v-if="item.avatar">
                  <img
                    :src="item.avatar"
                    alt="Avatar"
                    class="card-img-top"
                    style="width: 70px; height: 60px"
                  />
                </template>
                <template v-else> - </template>
              </td>
              <td>{{ item.name }}</td>
              <td class="text-nowrap text-muted">{{ item.type }}</td>
              <td class="">
                <span class="tag" :class="item.is_online_color">{{
                  item.is_online_str
                }}</span>
              </td>
              <td>{{ item.created_at_str || "-" }}</td>
              <td>
                <button
                  class="btn btn-secondary btn-sm dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Actions
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2"
                  style="width: 200px"
                >
                  <a class="dropdown-item btn-sm cursor-pointer"
                    ><font-awesome-icon
                      icon="ticket-alt"
                      class="text-primary"
                    />&nbsp; Update Broadcast</a
                  >
                  <a class="dropdown-item btn-sm cursor-pointer"
                    ><font-awesome-icon
                      icon="ticket-alt"
                      class="text-primary"
                    />&nbsp; Update Status</a
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty
          description="No data found"
          v-if="total_rows == 0"
          :image="emptyStateImage"
        ></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
        <small
          >Showing {{ showingFrom }} to {{ showingUntil }} of
          {{ total_rows }} entries</small
        >
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
    </div>
    <div></div>

    <b-modal
      size="lg"
      id="add"
      title="Create Broadcast"
      hide-footer
      v-model="modalCreateBroadcastChannel"
      @hide="closeModalChannel"
    >
      <b-form
        @submit.prevent="createBroadcastChannel"
        @reset="closeModalChannel"
      >
        <b-form-group label="Channel ID">
          <el-select
            v-model="broadcast.channel_id"
            placeholder="Select channel"
            class="mr-2 w-full"
            filterable
            remote
          >
            <el-option
              v-for="item in listChannel"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="Title">
          <el-input placeholder="Title" v-model="broadcast.title" />
        </b-form-group>
        <b-form-group label="Image">
          <b-form-file
            v-model="upload.image"
            :state="Boolean(upload.image)"
            class="mt-2 border-1 p-2"
            accept=".png, .jpg, .jpeg"
            @input="onChangeFile($event, 'image')"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>

          <small class="text-warning"
            ><i>*Submit an image before creating a broadcast</i></small
          ><br />
          <el-button @click="uploadImage" type="" size="small" plain round>
            Submit Image
          </el-button>
        </b-form-group>
        <!-- <b-form-group label="Message">
          <el-input placeholder="Message" v-model="broadcast.message" />
        </b-form-group> -->

        <b-form-group label="Message">
          <quill-editor
            v-model="broadcast.message"
            ref="message"
            :options="editorOption"
            style="height: 150px"
          >
          </quill-editor> </b-form-group
        ><br /><br /><br /><br />
        <b-form-group label="Button">
          <el-input placeholder="Button" v-model="broadcast.button" />
        </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button
            :disabled="!isFormValid"
            @click="createBroadcastChannel"
            class="mr-2 ml-2"
            size="small"
            type="primary"
            >Submit</el-button
          >
          <el-button @click="onHide" size="small" class="mr-2"
            >Cancel</el-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal
      size="xl"
      id="edit"
      title="Update Channel"
      hide-footer
      v-model="modalUpdateChannel"
      @hide="closeModalChannel"
    >
      <b-form
        @submit.prevent="createBroadcastChannel"
        @reset="closeModalChannel"
      >
        <b-form-group label="Title">
          <el-input placeholder="Title" v-model="selected_item.title" />
        </b-form-group>
        <b-form-group label="image_url">
          <el-input placeholder="image_url" v-model="selected_item.image_url" />
        </b-form-group>
        <b-form-group label="target_url">
          <el-input
            placeholder="target_url"
            v-model="selected_item.target_url"
          />
        </b-form-group>
        <b-form-group label="target_page">
          <el-input
            placeholder="target_page"
            v-model="selected_item.target_page"
          />
        </b-form-group>
        <b-form-group label="description">
          <el-input
            placeholder="description"
            v-model="selected_item.description"
          />
        </b-form-group>
        <b-form-group label="company_ids">
          <el-input
            placeholder="company_ids"
            v-model="selected_item.company_ids"
          />
        </b-form-group>
        <b-form-group label="user_ids">
          <el-input placeholder="user_ids" v-model="selected_item.user_ids" />
        </b-form-group>
        <b-form-group label="user_type">
          <el-select
            class="w-full"
            v-model="selected_item.user_type"
            placeholder="user_type"
          >
            <el-option
              v-for="category in online_option"
              :key="category.value"
              :value="category.value"
              :label="category.name"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button
            @click="createBroadcastChannel"
            class="mr-2 ml-2"
            size="small"
            type="primary"
            >Submit</el-button
          >
          <el-button @click="onHide" size="small" class="mr-2"
            >Cancel</el-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import {
  UPLOAD_IMAGE,
  GET_CHANNEL,
  GET_BROADCAST_CHANNEL,
  CREATE_BROADCAST_CHANNEL,
  GET_BROADCAST_BY_ID_CHANNEL,
} from "@/store/modules/channel";
import emptyStateImage from "@/assets/images/empty-state.png";
import popupErrorMessages from "@/library/popup-error-messages";
import Pagination from "../components/Pagination.vue";
import base64File from "@/library/base64File";
import imageCompress from "@/library/vue-image-compression";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: { Pagination, quillEditor },
  name: "Channel",
  metaInfo: {
    title: "Channel",
  },
  data() {
    return {
      image: null,
      image_base64: null,
      upload: {
        image: "",
      },

      user: {
        search_by: "full_name",
        search_keyword: "",
        searchType: 1,
        search_options: [
          { value: "full_name", text: "Full Name" },
          { value: "email", text: "Email" },
          { value: "phone_number", text: "Phone Number" },
        ],
      },
      broadcast: {
        channel_id: "",
        title: "",
        message: "",
        button: "",
        // company_id: '',
      },
      // history_coin: [],
      companies: {
        rows: [],
      },
      listBroadcastByID: [],
      isLoadingHistory: false,
      emptyStateImage,
      modalCreateBroadcastChannel: false,
      closeModalChannel: false,
      modalUpdateChannel: false,
      showSearch: true,
      editorOption: {
        placeholder: "1. Test ...",
      },
      search_options: [
        { value: "full_name", text: "Name" },
        { value: "email", text: "Email" },
        { value: "phone_number", text: "Phone Number" },
      ],
      online_option: [
        {
          value: false,
          label: "False",
        },
        {
          value: true,
          label: "True",
        },
      ],
      type_option: [
        {
          value: "platform",
          label: "Platform",
        },
        {
          value: "company",
          label: "Company",
        },
      ],
      per_page: 20,
      size: 0,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: "",
      search_by: "",
      listBroadcast: [],
      listChannel: [],
      data_image: "",
      timeoutSearchCompany: null,
      selected_item: {
        title: "",
        image_url: "",
        target_url: "",
        target_page: [],
        description: "",
        user_type: "",
        company_ids: [],
        user_ids: [],
        file_url: null,
      },
      file_tmp: "",
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, "day").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        search_by: "full_name",
        search_keyword: "",
        start_date: moment().subtract(30, "day").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        status_filter: "",
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), "days"),
      },
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? (this.page - 1) * this.per_page + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.listBroadcast.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isFormValid() {
      return (
        this.broadcast.channel_id &&
        this.broadcast.title &&
        this.broadcast.message
      );
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
    this.getListChannel();
  },
  methods: {
    async onChangeFile(event, target) {
      console.log("target image ==>", target);
      if (this.upload[target]) {
        const compressed = await imageCompress(this.upload[target]);
        this.upload[`${target}_base64`] = await base64File(compressed);
        this.file_tmp = this.upload[`${target}_base64`];
      }
    },

    async doUpdateChannel() {
      this.$confirm(
        "You will update the Event. Make sure you have input the data correctly. Continue?",
        "Confirmation",
        {
          type: "info",
          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              done();
              const requestBody = {
                title: this.selected_item.name,
                image_url: this.selected_item.updated_by,
                target_url: this.selected_item.description,
                content: this.selected_item.content,
                efective_date: this.selected_item.efective_date,
              };
              const id = this.selected_transaction.id;
              await this.$store
                .dispatch(UPDATE_LEGAL, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: "Success",
                    type: "success",
                    message: "PDP updated",
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async createBroadcastChannel() {
      const storedImage = localStorage.getItem("uploadedImage");
      const uploadedImage = storedImage ? JSON.parse(storedImage) : null;
      try {
        const requestBody = {
          name: this.broadcast.name,
          channel_id: this.broadcast.channel_id,
          file_url: uploadedImage?.file_url || null,
          message: this.broadcast.message,
          button: this.broadcast.button,
          title: this.broadcast.title,
        };
        const response = await this.$store.dispatch(CREATE_BROADCAST_CHANNEL, {
          data: requestBody,
        });
        this.broadcast.name = "";
        this.broadcast.title = "";
        this.broadcast.channel_id = "";
        this.upload.file_url = null;
        this.broadcast.message = "";
        this.broadcast.button = "";
        this.data_channel = response;
        this.$message({
          title: "Success",
          type: "success",
          message: "Broadcast Channel created",
        });
        this.onHide();
        this.getList();
      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },

    async uploadImage() {
      this.company_id = parseInt(this.company_id);
      try {
        const requestBody = {
          file_data: this.file_tmp,
          filename: "image-avatar",
          source: "vdashboard",
          product: "avatar",
          category: "system",
        };
        const response = await this.$store.dispatch(UPLOAD_IMAGE, {
          data: requestBody,
        });
        const { file_id, file_url } = response.data.rows;
        this.data_image = { file_id, file_url };
        localStorage.setItem("uploadedImage", JSON.stringify(this.data_image));
        this.file_tmp = "";
        this.$message({
          title: "Success",
          type: "success",
          message: "Upload success created",
        });

        this.getList();
      } catch (err) {
        console.error("Upload Image Error:", err.response?.data || err.message);
        popupErrorMessages(err.response?.data);
      }
    },
    onHide() {
      this.modalCreateBroadcastChannel = false;
      this.modalUpdateChannel = false;
      this.modalUpdateLStatus = false;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandlerGami() {
      this.channel_id = this.channel.channel_id;
      this.getList();
    },
    async searchHandler() {
      if (!this.broadcast.channel_id) {
        this.$message.error("Please select a channel before searching.");
        return;
      }
      await this.getListByID();
    },
    async getListByID() {
      this.isLoading = true;

      try {
        await this.$store.dispatch(GET_BROADCAST_BY_ID_CHANNEL, {
          channel_id: this.broadcast.channel_id,
          user: { owned_by: 24963 },
        });

        const { rows, count } = this.$store.getters.broadcast_by_id_channel;

        this.total_rows = count || 0;
        this.listBroadcastByID = rows.map((v) => v);
      } catch (error) {
        this.$message.error("Failed to fetch broadcasts.");
      } finally {
        this.isLoading = false;
      }
    },

    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_BROADCAST_CHANNEL, {}).catch(() => {});
      const { rows, count } = this.$store.getters.broadcast_channel;
      this.total_rows = count || 0;
      this.listBroadcast = rows.map((v) => {
        v.birth_place_date =
          v.birth_place && v.birthday
            ? [v.birth_place, moment(v.birthday).format("DD MMM YYYY")].join(
                ", "
              )
            : "-";
        return v;
      });
      this.isLoading = false;
    },
    async getListChannel() {
      this.isLoading = true;
      await this.$store
        .dispatch(GET_CHANNEL, {
          filter: "status,all",
        })
        .catch(() => {});
      const { rows, count } = this.$store.getters.channel;
      this.listChannel = rows.map((v) => {
        v.birth_place_date =
          v.birth_place && v.birthday
            ? [v.birth_place, moment(v.birthday).format("DD MMM YYYY")].join(
                ", "
              )
            : "-";
        return v;
      });
      this.isLoading = false;
    },
    formatDate(timestamp) {
      return moment(timestamp).format("D MMMM YYYY, HH:mm A");
    },
    formatNumber(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}

.scrollable-card-body {
  max-height: 400px;
  overflow-y: auto;
}

.el-timeline-item__timestamp {
  color: orange;
}

.custom-card {
  width: 500px;
  height: auto;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
